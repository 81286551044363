import {
  FETCH_LESSONS_REQUEST,
  FETCH_LESSONS_SUCCESS,
  FETCH_LESSONS_FAILURE,
  RESET_LESSONS,
} from '../constants/actions-constants';

const inistialState = {
  loading: false,
  lessons: [],
  error: '',
};

const fetchLessonsReducer = (state = inistialState, action) => {
  switch (action.type) {
    case FETCH_LESSONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LESSONS_SUCCESS:
      return {
        ...state,
        lessons: action.lessons,
        loading: false,
        error: '',
      };
    case FETCH_LESSONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        lessons: '',
      };
    case RESET_LESSONS:
      return {
        ...state,
        lessons: [],
      };
    default:
      return state;
  }
};

export default fetchLessonsReducer;
